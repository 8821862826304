import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Modal from './components/Modal'
import Input from './components/Input'
const mixins = {
    data(){
        return {
            tokenHeader:{
                headers:{
                    'Authorization': `Bearer ${this.$store.getters.token}` 
                }
            }
        }
    },
    methods:{
        apiUrl(path){
            return 'https://vtpauctions.teknicode.uk/api/'+(path ? path : ''); 
            // return 'http://localhost:9001/'+(path ? path : ''); 
        }
    },
    mounted(){
        this.axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response && error.response.status === 401) {
                this.$store.commit('token',null)
                this.$router.push('login')
            }
            return error;
        });
    }
}

var app = createApp(App)
app.use(store);
app.use(router);
app.component("Modal",Modal);
app.component("Input",Input);
app.use(VueAxios, axios);
app.mixin(mixins);

app.mount('#app')
