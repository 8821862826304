<template>
    <div class="p-2">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="shrink">Lot Number</th>
                    <th>Description</th>
                    <th class="shrink">Lot Number</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(chunk, index) in lotChunks" :key="index">
                    <template v-for="lot in chunk" :key="lot.id">
                        <td class="shrink">{{ lot.lot_no }}</td>
                        <td>{{ lot.description }}</td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
table th, table td{
    border:1px solid #000 !important;
}
</style>

<script>
export default {
    name: 'Catalogue',
    props:{
        auction_id: String
    },
    data(){
        return {
            lots: []
        }
    },
    computed: {
        lotChunks() {
            const chunkSize = 2;
            const chunks = [];
            for (let i = 0; i < this.lots.length; i += chunkSize) {
                chunks.push(this.lots.slice(i, i + chunkSize));
            }
            return chunks;
        }
    },
    mounted(){
        this.getLots();
    },
    methods:{
        getLots(){
            this.axios.get(this.apiUrl(`lots/auction/${this.auction_id}`), this.tokenHeader).then(response=>{
                this.lots = response.data;
            })
        }
    }
}
</script>
