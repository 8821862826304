<template>
    <Navbar page="lots"/>
    <div class="container">
        <div class="d-flex justify-content-between my-3">   
            <input type="text" class="form-control" style="width:200px;" placeholder="Search" v-model="search"/>
            <button @click="showNewLot" class="btn btn-secondary">New Lot</button>
        </div>
        <span class="ms-2" v-if="seller_id">Filtered for Seller ID: {{seller_id}} <a href="#" @click="$router.push('/lots')">(remove filter)</a></span>
        <span class="ms-2" v-if="auction_id">Filtered for auction ID: {{auction_id}} <a href="#" @click="$router.push('/lots')">(remove filter)</a></span>

        <table class="table table-striped table-hover">
        <thead>
        <tr>
            <th class="shrink">Auction ID</th>
            <th class="shrink">Lot No</th>
            <th class="shrink">Auction Date</th>
            <th class="shrink">Seller</th>
            <th class="shrink">Bidder</th>
            <th>Description</th>
            <th class="shrink text-end">Hammer</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="lot in filteredLots" :key="lot.id" @click="setLot(lot)">
            <td class="shrink">{{lot.auction_id}}</td>
            <td class="shrink">{{lot.lot_no}}</td>
            <td class="shrink">{{!lot.auction_date ? 'Not set' : lot.auction_date}}</td>
            <td class="shrink">{{lot.seller_first_name}} {{lot.seller_last_name}}</td>
            <td class="shrink">{{lot.bidder_first_name}} {{lot.bidder_last_name}}</td>
            <td>{{lot.description}}</td>
            <td class="shrink text-end">{{lot.hammer}}</td>
        </tr>
        </tbody>
        </table>
    </div>

    <Modal :show="selected_lot!=null" title="Update Lot" :width="400" @close="selected_lot=null">
        <div class="row mb-1">
            <Input class="col-6" :required="true" placeholder="Auction ID" label="Auction ID" v-model="selected_lot.auction_id" :readonly="true"/>
            <Input class="col-6" :required="true" placeholder="Lot No" label="Lot No" v-model="selected_lot.lot_no"/>
        </div>
        <div class="row mb-1">
            <Input class="col-6" placeholder="Reserve" label="Reserve" v-model="selected_lot.reserve"/>
            <Input class="col-6" :required="true" placeholder="Seller ID" label="Seller ID" v-model="selected_lot.seller_id"/>
        </div>
        <div class="row mb-1">
            <Input class="col-6" placeholder="Bidder ID" label="Bidder ID" v-model="selected_lot.bidder_id"/>
            <Input class="col-6" placeholder="Hammer" label="Hammer" v-model="selected_lot.hammer"/>
        </div>
        <Input class="mb-1" :required="true" :rows="5" placeholder="Description" label="Description" v-model="selected_lot.description"/>

        <template v-slot:footer>
            <div class="d-flex justify-content-between">
                <button @click="deleteLot()" class="btn btn-sm btn-danger me-2">Delete</button>
                <div> 
                    <button @click="selected_lot=null" class="btn btn-sm btn-secondary me-2">Cancel</button>
                    <button @click="saveChanges()" class="btn btn-sm btn-primary" :disabled="!validToSubmitSelected">Save Changes</button>
                </div>
            </div>
        </template>
    </Modal>
    <Modal :show="show_new_lot" title="New Lot" :width="400" @close="show_new_lot=false">
        <div class="row mb-1">
            <Input class="col-6" :required="true" placeholder="Auction ID" label="Auction ID" v-model="new_lot.auction_id" :disabled="auction_id != null"/>
            <Input class="col-6" :disabled="!new_lot.auction_id" :required="true" placeholder="Lot No" label="Lot No" v-model="new_lot.lot_no"/>
        </div>
        <div class="row mb-1">
            <Input class="col-6" placeholder="Reserve" label="Reserve" v-model="new_lot.reserve"/>
            <Input class="col-6" :required="true" placeholder="Seller ID" label="Seller ID" v-model="new_lot.seller_id"/>
        </div>
        <Input class="mb-1" :rows="5" :required="true" placeholder="Description" label="Description" v-model="new_lot.description"/>

        <template v-slot:footer>
            <button @click="show_new_lot=false" class="btn btn-sm btn-secondary me-2">Cancel</button>
            <button @click="saveNewLot()" class="btn btn-sm btn-primary" :disabled="!validToSubmitNew">Save</button>
        </template>
    </Modal>

    <Modal :show="show_confirm_delete" title="Confirm">
        Confirm you want to delete this lot
        <template v-slot:footer>
            <button class="btn btn-secondary btn-sm me-2" @click="show_confirm_delete=false">Cancel</button>
            <button class="btn btn-danger btn-sm" @click="confirmDeleteLot">Confirm</button>
        </template>
    </Modal>

    <Modal :show="error!=''" title="Error">
        {{error}}
        <template v-slot:footer>
            <button class="btn btn-secondary btn-sm" @click="error=''">Ok</button>
        </template>
    </Modal>
</template>

<script>
import Navbar from '../components/Navbar'
import _ from 'lodash'
export default{
    name: 'Lots',
    components:{
        Navbar
    },
    props:{
        seller_id: String,
        auction_id: String
    },
    data(){
        return {
            lots: [],
            search:"",
            selected_lot: null,
            selected_lot_copy: null,
            error: '',
            show_new_lot: false,
            new_lot:{
                lot_no: 0,
                auction_id: '',
                seller_id: '',
                bidder_id: '',
                hammer: '',
                description: ''
            },
            show_confirm_delete: false
        }
    },
    watch:{
        "new_lot.auction_id":function(){
            this.new_lot.lot_no = this.nextLotNo
        }
    },
    computed:{
        nextLotNo(){
            var id = 0;
            this.lots.forEach(lot => {
                if( parseInt(lot.lot_no) > id && parseInt(lot.auction_id) == parseInt(this.new_lot.auction_id) ){
                    id = parseInt(lot.lot_no)
                }
            })
            return id+1;
        },
        filteredLots(){
            if(this.search == ""){
                return this.lots;
            }
            return this.lots.filter(lot=>{
                var found = false;
                _.forEach(lot, (value)=>{
                    if(!found && String(value).toLowerCase().includes(this.search.toLowerCase())){
                        found=true;
                    }
                })

                if(found){
                    return true;
                }

                return false;
            });
        },
        urlPath(){
            if(this.seller_id){
                return 'lots/seller/'+this.seller_id
            }else if(this.auction_id){
                return 'lots/auction/'+this.auction_id
            }else{
                return 'lots'
            }
        },
        validToSubmitSelected(){
            return !_.isEqual(this.selected_lot, this.selected_lot_copy);
        },
        validToSubmitNew(){
            if(!this.new_lot.auction_id || this.new_lot.auction_id == ""){
                return false;
            }
            if(!this.new_lot.seller_id || this.new_lot.seller_id == ""){
                return false;
            }
            if(!this.new_lot.description || this.new_lot.description == ""){
                return false;
            }
            return true;
        }
    },
    methods:{
        setLot(lot){
            this.selected_lot = _.cloneDeep(lot);
            this.selected_lot_copy = _.cloneDeep(lot);
        },
        getLots(){
            this.axios.get(this.apiUrl(this.urlPath), this.tokenHeader).then(response=>{
                this.lots = response.data
            });
        },
        saveChanges(){
            var changed = {}
            for(const [key, value] of Object.entries(this.selected_lot)){
                if( !_.isEqual( value, this.selected_lot_copy[key] )){
                    changed[key] = value;
                }
            }
            delete changed.auction_id;
            this.axios.patch(this.apiUrl('lot/'+this.selected_lot.id), changed, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getLots();
                    this.selected_lot = null;
                    this.selected_lot_copy = null;
                }else{
                    this.error='Error saving lot';
                }
            });
        },
        saveNewLot(){
            this.axios.post(this.apiUrl('lot'), this.new_lot, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getLots();
                    this.new_lot = {
                        auction_id: '',
                        seller_id: '',
                        bidder_id: '',
                        hammer: '',
                        description: ''
                    }
                    this.show_new_lot=false;
                }else{
                    this.error='Error saving lot';
                }
            });
        },
        deleteLot(){
            this.show_confirm_delete = true;
        },
        confirmDeleteLot(){
            this.axios.delete(this.apiUrl('lot/'+this.selected_lot.id), this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getLots();
                    this.selected_lot = null;
                    this.selected_lot_copy = null;
                    this.show_confirm_delete = false;
                }else{
                    if(response.data.code == 23000){
                        this.error='Unable to delete this lot. Other data is linked to this record.';
                    }else{
                        this.error='Error deleting lot';
                    }
                }
            });
        },
        showNewLot(){
            if(this.auction_id){
                this.new_lot.auction_id = this.auction_id
            }
            this.show_new_lot=true;
        }
    },
    mounted(){
        this.getLots();
    }
}
</script>
