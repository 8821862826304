<template>
    <div class="w-100 h-100 bg-dark d-flex justify-content-center align-items-center">
        <div class="card" style="width: 18rem;">
            <div class="card-body" v-if="!codeRequested">
                <div class="alert alert-danger" v-if="invalid">
                    Incorrect login details
                </div>
                <div class="form-group mb-2">
                    <input type="text" class="form-control" placeholder="Username" v-model="username"/>
                </div>
                <div class="form-group mb-2">
                    <input type="password" class="form-control" placeholder="Password" v-model="password"/>
                </div>
                <button @click="getCode" class="btn btn-primary w-100">Login</button>
            </div>
            <div class="card-body" v-if="codeRequested">
                <div class="mb-2">
                    A security code has been sent to:<br/>********{{codeRequested.mobile.substr(-4)}}<br/>
                    <small>This code will expire in 10 minutes</small>
                </div>
                <div class="alert alert-danger" v-if="invalid">
                    Incorrect code
                </div>
                <div class="form-group mb-2">
                    <input type="text" class="form-control" placeholder="Code" v-model="code"/>
                </div>
                <button @click="login" class="btn btn-primary w-100">Login</button>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'Login',
    data(){
        return {
            username: '',
            password: '',
            invalid: false,
            code: '',
            codeRequested: false
        }
    },
    methods:{
        getCode(){
            this.axios.post(this.apiUrl('code'), {username: this.username, password: this.password}).then(response=>{
                if(response.status == 201){
                    this.codeRequested = response.data; 
                    this.invalid=false;
                }else{
                    this.invalid=true;
                }
            })
        },
        login(){
            this.axios.post(this.apiUrl('tokens'), {username: this.username, password: this.password, code: this.code}).then(response=>{
                if(response.status == 201){
                    this.$store.commit('token', response.data.access_token)
                    this.$store.commit('user', response.data.user)
                    this.$router.push({name: 'Customers'})
                    this.invalid=false;
                }else{
                    this.invalid=true;
                }
            })
        }
    }
}
</script>
