<template>
    <div class="my-3 container" v-if="invoice">
        <div class="row">
            <div class="col-6 pt-4">
                <h1>Buyer Invoice</h1>
                <h3>Invoice ID: {{invoice_id}}</h3>
                <h5>Date: {{invoice.auction.date}}</h5>
                <p class="lead">
                    <small style="font-weight:bold;">INVOICE TO</small><br/>
                    {{invoice.customer.first_name}} {{invoice.customer.last_name}}<br/>
                    {{invoice.customer.address}}<br/>
                    {{invoice.customer.post_code}}<br/>
                    {{invoice.customer.telephone}}
                </p>
            </div>
            <div class="col-6 text-end">
                <img src="../assets/logo.png" style="max-height:140px;margin-bottom:5mm;"/>
                <div>Mobile: 07832 962982</div>
                <div>Email: thevtpauctions@gmail.com</div>
            </div>
        </div>
        <hr/>
    </div>

    <div class="my-3 container" v-if="invoice">
        <table class="table table-striped">
        <thead>
            <tr>
                <th class="shrink">Lot No</th>
                <th>Description</th>
                <th class="shrink text-end">Sale Price</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="lot in invoice.lots" :key="lot.id">
                <td class="shrink">{{lot.lot_no}}</td>
                <td>{{lot.description}}</td>
                <td class="shrink text-end">£{{lot.hammer.toFixed(2)}}</td>
            </tr>
            <tr>
                <td colspan="2" class="text-end">Sub Total</td>
                <td class="shrink text-end">£{{sub_total}}</td>
            </tr>
            <tr>
                <td colspan="2" class="text-end">17.5% Commission</td>
                <td class="shrink text-end">£{{commission}}</td>
            </tr>
            <tr style="font-weight:bold;">
                <td colspan="2" class="text-end">Total</td>
                <td class="shrink text-end">£{{total}}</td>
            </tr>
        </tbody>
        </table>
    </div>
</template>

<script>
/* import _ from 'lodash' */
export default{
    name: 'BuyerInvoice',
    props:{
        auction_id: String,
        customer_id: String
    },
    data(){
        return {
            invoice: null,
            /* commission_percentage: 15, */
            /* lot_charge: 1.00, */
            error: ''
        }
    },
    computed:{
        sub_total(){
            var total = 0;
            this.invoice.lots.forEach(lot=>{
                total += lot.hammer
            });
            return total.toFixed(2);
        },
        commission(){
            return (this.sub_total*0.175).toFixed(2);
        },
        total(){
            return (parseFloat(this.sub_total)+parseFloat(this.commission)).toFixed(2);
        },
        /* total_commission(){ */
        /*     return (parseFloat(this.subtotal)*(parseFloat((this.commission_percentage ? this.commission_percentage : 0))/100)).toFixed(2); */
        /* }, */
        /* total_lot_charge(){ */
        /*     return (this.lot_charge ? (this.lot_charge*this.invoice.lots.length) : 0).toFixed(2); */
        /* }, */
        /* total(){ */
        /*     var total = this.subtotal; */
        /*     total = total-this.total_commission; */
        /*     total = total-this.total_lot_charge; */
        /*     return total.toFixed(2); */
        /* }, */
        /* validToSave(){ */
        /*     if(!_.isEmpty(this.invoice.invoice)){ */
        /*         if(parseFloat(this.invoice.invoice.commission) == parseFloat(this.commission_percentage) && parseFloat(this.invoice.invoice.lot_charge) == parseFloat(this.lot_charge)){ */
        /*             return false; */
        /*         } */
        /*     }else if(this.commission_percentage == '' || this.lot_charge == ''){ */
        /*         return false; */
        /*     } */
        /*     return true; */
        /* }, */
        invoice_id(){
            return this.invoice.auction.id+''+this.customer_id;
        }
    },
    methods:{
        getInvoice(){
            this.axios.get(this.apiUrl(`buyer_invoice/${this.auction_id}/${this.customer_id}`), this.tokenHeader).then(response=>{
                this.invoice = response.data
                /* if(!_.isEmpty(this.invoice.invoice) && this.invoice.invoice.lot_charge != ''){ */
                /*     this.lot_charge = this.invoice.invoice.lot_charge */
                /* } */
                /* if(!_.isEmpty(this.invoice.invoice)){ */
                /*     this.commission_percentage = this.invoice.invoice.commission */
                /* } */
            });
        },
        /* save(){ */
        /*     this.axios.post(this.apiUrl(`seller_invoice/${this.auction_id}/${this.customer_id}`), {lot_charge: this.lot_charge, commission:this.commission_percentage}, this.tokenHeader).then(response=>{ */
        /*         if(response.data.status == 'success'){ */
        /*             this.getInvoice(); */
        /*         }else{ */
        /*             this.error = 'Error saving invoice'; */
        /*         } */
        /*     }); */
        /* } */
    },
    mounted(){
        this.getInvoice();
    }
}
</script>
