<template>
    <Navbar page="settings"/>
    <div class="container pt-3">
        <h3>Settings</h3>
        <Input class="mb-1" placeholder="New Password" label="New Password" v-model="new_password" style="max-width:400px;"/>
        <Input class="mb-3" placeholder="Confirm New Password" label="Confirm Password" v-model="confirm_password" style="max-width:400px;"/>
        <div class="alert alert-danger" v-if="new_password!=confirm_password">Passwords do not match</div>
        <button class="btn btn-primary" :disabled="new_password!=confirm_password || new_password == ''" @click="save">Save Changes</button>
    </div>

    <Modal :show="error!=''" title="Error">
        {{error}}
        <template v-slot:footer>
            <button class="btn btn-secondary btn-sm" @click="error=''">Ok</button>
        </template>
    </Modal>

    <Modal :show="show_success" title="Success">
        Password successfully updated
        <template v-slot:footer>
            <button @click="show_success=false" class="btn btn-sm btn-primary">Ok</button>
        </template>
    </Modal>
</template>

<script>
import Navbar from '../components/Navbar'
export default{
    name: 'Settings',
    components:{
        Navbar
    },
    data(){
        return {
            new_password: '',
            confirm_password: '',
            error: '',
            show_success: false
        }
    },
    methods:{
        save(){
            this.axios.post(this.apiUrl('password'), {new_password: this.new_password}, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    //show success message and reset the form
                    this.show_success=true;
                    this.new_password = '';
                    this.confirm_password = '';
                }else{
                    //show an error message
                    this.error = 'Error saving password';
                }
            });
        }
    }
}
</script>
