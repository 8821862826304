<template>
    <Navbar page="customers"/>
    <div class="container">
        <div class="d-flex justify-content-between align-items-center">
            <input type="text" class="form-control my-3" style="width:200px;" placeholder="Search" v-model="search"/>
            <div>
                <button class="btn btn-primary" @click="show_new_customer=true">New Customer</button>
            </div>
        </div>

        <table class="table table-striped table-hover align-middle">
        <thead>
        <tr>
            <th class="shrink">Customer ID</th>
            <th class="shrink">First Name</th>
            <th class="shrink">Last Name</th>
            <th class="shrink">Telephone</th>
            <th class="shrink">Mobile</th>
            <th>Email</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="customer in filteredCustomers" :key="customer.id" @click="selectCustomer(customer)">
            <td class="shrink">{{customer.id}}</td>
            <td class="shrink">{{customer.first_name}}</td>
            <td class="shrink">{{customer.last_name}}</td>
            <td class="shrink">{{customer.telephone}}</td>
            <td class="shrink">{{customer.mobile}}</td>
            <td>{{customer.email}}</td>
            <td class="shrink"><button class="btn btn-sm btn-link" @click.stop="$router.push('/lots/seller/'+customer.id)">Lots</button></td>
        </tr>
        </tbody>
        </table>
    </div>

    <Modal :show="selected_customer!=null" title="Update Customer" :width="400">
        <Input class="mb-1" placeholder="First Name" label="First Name" v-model="selected_customer.first_name"/>
        <Input class="mb-1" placeholder="Last Name" label="Last Name" v-model="selected_customer.last_name"/>
        <Input class="mb-1" placeholder="Address" label="Address" v-model="selected_customer.address"/>
        <Input class="mb-1" placeholder="Post Code" label="Post Code" v-model="selected_customer.post_code"/>
        <Input class="mb-1" placeholder="Telephone" label="Telephone" v-model="selected_customer.telephone"/>
        <Input class="mb-1" placeholder="Mobile" label="Mobile" v-model="selected_customer.mobile"/>
        <Input placeholder="Email Address" label="Email Address" v-model="selected_customer.email"/>

        <template v-slot:footer>
            <div class="d-flex justify-content-between">
                <button @click="deleteCustomer()" class="btn btn-sm btn-danger me-2">Delete</button>
                <div> 
                    <button @click="selected_customer=null" class="btn btn-sm btn-secondary me-2">Cancel</button>
                    <button @click="saveChanges()" class="btn btn-sm btn-primary" :disabled="!validToSubmitSelected">Save Changes</button>
                </div>
            </div>
        </template>
    </Modal>

    <Modal :show="show_new_customer" title="New Customer" :width="400">
        <Input class="mb-1" placeholder="ID" label="ID" :required="true" v-model="new_customer.id"/>
        <Input class="mb-1" placeholder="First Name" :required="true" label="First Name" v-model="new_customer.first_name"/>
        <Input class="mb-1" placeholder="Last Name" :required="true" label="Last Name" v-model="new_customer.last_name"/>
        <Input class="mb-1" placeholder="Address" label="Address" v-model="new_customer.address"/>
        <Input class="mb-1" placeholder="Post Code" label="Post Code" v-model="new_customer.post_code"/>
        <Input class="mb-1" placeholder="Telephone" label="Telephone" v-model="new_customer.telephone"/>
        <Input class="mb-1" placeholder="Mobile" label="Mobile" v-model="new_customer.mobile"/>
        <Input placeholder="Email Address" label="Email Address" v-model="new_customer.email"/>

        <template v-slot:footer>
            <div class="text-right">
                <div> 
                    <button @click="show_new_customer=false" class="btn btn-sm btn-secondary me-2">Cancel</button>
                    <button @click="saveNewCustomer()" class="btn btn-sm btn-primary" :disabled="!validToSubmitNewCustomer">Save</button>
                </div>
            </div>
        </template>
    </Modal>

    <Modal :show="error!=''" title="Error">
        {{error}}
        <template v-slot:footer>
            <button class="btn btn-secondary btn-sm" @click="error=''">Ok</button>
        </template>
    </Modal>
</template>

<script>
import Navbar from '../components/Navbar'
import _ from 'lodash'
export default{
    name: 'Customers',
    components:{
        Navbar
    },
    data(){
        return {
            customers: [],
            search:"",
            selected_customer:null,
            selected_customer_copy:null,
            show_new_customer: false,
            new_customer: {
                id: 0,
                first_name: '',
                last_name: '',
                address: '',
                post_code: '',
                telephone: '',
                mobile: '',
                email: ''
            },
            error: ''
        }
    },
    watch:{
        show_new_customer(){
            if(this.show_new_customer){
                this.new_customer.id = this.nextID
            }
        }
    },
    computed:{
        nextID(){
            var highest = 0;
            this.customers.forEach(customer => {
                if( parseInt(customer.id) > highest ){
                    highest = parseInt(customer.id)
                }
            })
            return highest+1;
        },
        filteredCustomers(){
            if(this.search == ""){
                return this.customers;
            }
            return this.customers.filter(customer=>{
                var found = false;
                _.forEach(customer, (value)=>{
                    if(!found && value.toString().toLowerCase().includes(this.search.toLowerCase())){
                        found=true;
                    }
                })

                if(found){
                    return true;
                }

                return false;
            });
        },
        validToSubmitSelected(){
            return !_.isEqual(this.selected_customer, this.selected_customer_copy);
        },
        validToSubmitNewCustomer(){
            if( this.new_customer.first_name.trim() == "" || this.new_customer.last_name.trim() == "" ){
                return false;
            }

            return true;
        }
    },
    methods:{
        getCustomers(){
            this.axios.get(this.apiUrl('customers'), this.tokenHeader).then(response=>{
                this.customers = response.data
            });
        },
        selectCustomer(customer){
            this.selected_customer = _.cloneDeep(customer);
            this.selected_customer_copy = _.cloneDeep(customer);
        },
        saveChanges(){
            var changed = {}
            for(const [key, value] of Object.entries(this.selected_customer)){
                if( !_.isEqual( value, this.selected_customer_copy[key] )){
                    changed[key] = value;
                }
            }
            this.axios.patch(this.apiUrl('customer/'+this.selected_customer.id), changed, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getCustomers();
                    this.selected_customer = null;
                    this.selected_customer_copy = null;
                }else{
                    this.error = "Error updating customer"
                }
            });
        },
        saveNewCustomer(){
            this.axios.post(this.apiUrl('customer'), this.new_customer, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getCustomers();
                    this.show_new_customer = false;
                }else{
                    this.error = "Error creating new customer"
                }
            });
        },
        deleteCustomer(){
            this.axios.delete(this.apiUrl('customer/'+this.selected_customer.id), this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getCustomers();
                    this.selected_customer = null;
                    this.selected_customer_copy = null;
                }else{
                    this.error = "Error deleting customer"
                }
            });
        }
    },
    mounted(){
        this.getCustomers();
    }
}
</script>
