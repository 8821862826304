<template>
<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
<div class="container">
  <a class="navbar-brand" href="#">VTP Auctions</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" :class="{active: page=='customers'}" @click="$router.push('/customers')">
        <a class="nav-link" href="#">Customers</a>
      </li>
      <li class="nav-item" :class="{active: page=='auctions'}" @click="$router.push('/auctions')">
        <a class="nav-link" href="#">Auctions</a>
      </li>
      <li class="nav-item" :class="{active: page=='lots'}" @click="$router.push('/lots')">
        <a class="nav-link" href="#">Lots</a>
      </li>
      <!-- <li class="nav-item" :class="{active: page=='seller_invoices'}" @click="$router.push('/seller_invoices')">
        <a class="nav-link" href="#">Seller Invoices</a>
      </li>
      <li class="nav-item" :class="{active: page=='buyer_invoices'}" @click="$router.push('/buyer_invoices')">
        <a class="nav-link" href="#">Buyer Invoices</a>
      </li> -->
    </ul>
    <ul class="ms-auto navbar-nav">
      <li class="nav-item" :class="{active: page=='settings'}" @click="$router.push('/settings')">
        <a class="nav-link" href="#">Settings</a>
      </li>
      <li class="nav-item" @click="logout">
        <a class="nav-link" href="#">Logout</a>
      </li>
    </ul>
  </div>
</div>
</nav>
</template>

<script>
export default{
    name: 'Navbar',
    props:{
        page: String
    },
    methods:{
        logout(){
            this.$store.commit('user', null)
            this.$store.commit('token', null)
            this.$router.push({name: 'Login'})
        }
    }
}
</script>
