<template>
    <div class="modal_wrapper" v-if="show">
        <div class="card" :style="{'width': (width || 300)+'px'}">
            <h5 class="card-header py-3" v-if="title">
                {{title}}
            </h5>
            <div class="card-body" :style="{'max-height':(maxHeight ? maxHeight+'px' : '100%')}">
                <slot></slot>
            </div>
            <div v-if="$slots.footer" class="card-footer text-muted text-end">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'Modal',
    props:{
        title: String,
        show: Boolean,
        width: Number,
        maxHeight: Number
    }
}
</script>

<style scoped>
.modal_wrapper{
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:10000;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:rgba(0,0,0,0.5);
}
.modal_popup{
    background-color:#fff;
    width:300px;
    padding:15px;
}
</style>
