<template>
    <Navbar page="seller_invoices"/>
    <div class="container">
        <input type="text" class="form-control my-3" style="width:200px;" placeholder="Customer Number" v-model="search"/>

        <table class="table table-striped table-hover align-middle">
        <thead>
        <tr>
            <th class="shrink">Auction ID</th>
            <th class="shrink">Auction Date</th>
            <th class="shrink">Seller ID</th>
            <th class="shrink">Seller Name</th>
            <th>Telephone</th>
            <th class="shrink">Lot(s)</th>
            <th class="shrink">Hammer Total</th>
            <th class="shrink">Paid</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="invoice in filteredInvoices" :key="invoice.id" @click="selectInvoice(invoice)">
            <td class="shrink">{{invoice.auction.id}}</td>
            <td class="shrink">{{invoice.auction.date}}</td>
            <td class="shrink">{{invoice.customer.id}}</td>
            <td class="shrink">{{invoice.customer.first_name}} {{invoice.customer.last_name}}</td>
            <td>{{invoice.customer.telephone}}</td>
            <td class="shrink">{{invoice.lots.length}}</td>
            <td class="shrink">{{sumHammer(invoice.lots).toFixed(2)}}</td>
            <td class="shrink" @click.stop>
                <input v-if="invoice.invoice" type="text" v-model="invoice.invoice.amount_paid" class="form-control" @blur="saveChange(invoice)" style="width:100px;"/>
                <input v-if="!invoice.invoice" type="text" v-model="invoice.new_amount_paid" class="form-control" @blur="createInvoice(invoice)" style="width:100px;"/>
                <!-- <div v-if="invoice.invoice && invoice.invoice.paid == 1">Paid</div>
                <div v-else><button class="btn btn-sm btn-primary" @click.stop="markAsPaid(invoice)">Mark as Paid</button></div> -->
            </td>
        </tr>
        </tbody>
        </table>
    </div>

    <Modal :show="error!=''" title="Error">
        {{error}}
        <template v-slot:footer>
            <button @click="error=''" class="btn btn-sm btn-primary">Ok</button>
        </template>
    </Modal>

    <Modal :show="success" title="Success">
        Paid amount updated
    </Modal>

    <Modal :show="loading" title="Loading">
        Please wait
    </Modal>
</template>
<script>
import Navbar from '../components/Navbar'
/* import _ from 'lodash' */
export default{
    name: 'SellerInvoices',
    components:{
        Navbar
    },
    props:['auction_id' ],
    data(){
        return {
            invoices: [],
            invoice_paid_values: {},
            search: '',
            loading: false,
            error: '',
            success: false,
            default_commission_percentage: 17.5,
        }
    },
    mounted(){
        this.getInvoices();
    },
    computed:{
        filteredInvoices(){
            if(this.search == ""){
                return this.invoices;
            }
            return this.invoices.filter(invoice=>{
                var found = false;

                if(invoice.customer.id == this.search){
                    found = true;
                }

                /* _.forEach(invoice.customer, (value)=>{ */
                /*     if(!found && value.toString().toLowerCase().includes(this.search.toLowerCase())){ */
                /*         found=true; */
                /*     } */
                /* }) */

                if(found){
                    return true;
                }

                return false;
            });
        }
    },
    methods:{
        selectInvoice(invoice){
            this.$router.push(`/seller_invoice/${invoice.auction.id}/${invoice.customer.id}`)
        },
        sumHammer(lots){
            var total = 0;
            lots.forEach(lot => {
                total += (lot.hammer ? lot.hammer : 0)
            })
            return total;
        },
        getInvoices(){
            this.loading = true;
            this.axios.get(this.apiUrl(`seller_invoices${this.auction_id ? '/'+this.auction_id : ''}`), this.tokenHeader).then(response=>{
                this.invoices = response.data
                this.loading = false;

                // Initialize invoice_paid_values
                this.invoices.forEach(invoice=>{
                    if(invoice.invoice){
                        this.invoice_paid_values[invoice.invoice.auction_id+'_'+invoice.invoice.seller_id] = invoice.invoice.amount_paid;
                    }
                });
            });
        },
        changed(auction_id, seller_id, value){
            return value != this.invoice_paid_values[auction_id+'_'+seller_id];
        },
        // markAsPaid( invoice ){
        //     var invoiceCopy = JSON.parse(JSON.stringify(invoice));

        //     if(!invoiceCopy.invoice){
        //         invoiceCopy.invoice = {
        //             lot_charge: 1,
        //             commission: 0,
        //             adjustment_description: "",
        //             adjustment_value: 0
        //         }
        //         invoice.invoice = JSON.parse(JSON.stringify(invoiceCopy.invoice));
        //     }
        //     invoiceCopy.invoice.paid = 1;

        //     this.axios.post(this.apiUrl(`seller_invoice/${invoice.auction.id}/${invoice.customer.id}`), invoiceCopy.invoice, this.tokenHeader).then(response=>{
        //         if(response.data.status == 'success'){
        //             invoice.invoice.paid = 1;
        //         }else{
        //             this.error = 'Error updating invoice';
        //         }
        //     });
        // },
        saveChange(invoice){
            if( !this.changed(invoice.invoice.auction_id, invoice.invoice.seller_id, invoice.invoice.amount_paid) ){
                return false;
            }
            var invoiceCopy = JSON.parse(JSON.stringify(invoice));

            if(!invoiceCopy.invoice){
                invoiceCopy.invoice = {
                    lot_charge: 1,
                    commission: 0,
                    adjustment_description: "",
                    adjustment_value: 0
                }
                invoice.invoice = JSON.parse(JSON.stringify(invoiceCopy.invoice));
            }

            this.axios.post(this.apiUrl(`seller_invoice/${invoice.auction.id}/${invoice.customer.id}`), invoiceCopy.invoice, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    //update the invoice_paid_values
                    this.invoice_paid_values[invoice.invoice.auction_id+'_'+invoice.invoice.seller_id] = invoice.invoice.amount_paid;
                    this.success = true;
                    setTimeout(()=>{
                        this.success = false;
                    },1000)
                }else{
                    this.error = 'Error updating invoice';
                }
            });
        },
        createInvoice(invoice){
            this.axios.post(this.apiUrl(`seller_invoice/${this.auction_id}/${invoice.customer.id}`), {
                lot_charge: 1,
                commission: this.default_commission_percentage,
                adjustment_description: '',
                adjustment_value: 0,
                amount_paid: invoice.new_amount_paid
            }, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getInvoices();
                }else{
                    this.error = 'Error saving invoice';
                }
            });
        }
    }
}
</script>
