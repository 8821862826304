<template>
    <div class="form-group">
        <label v-if="label">{{label}}<span class="text-danger" v-if="required"> (required)</span></label>
        <input type="text" class="form-control" :value="modelValue" @input="updateValue" :placeholder="placeholder" :readonly="readonly" :disabled="disabled" />
    </div>
</template>

<script>
export default{
    name: 'Input',
    props:{
        modelValue: null,
        label: String,
        placeholder: String,
        required: Boolean,
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        updateValue(e){
            this.$emit('update:modelValue',e.target.value)
        }
    }
}
</script>

<style scoped>
label{
    color:#888;
}
</style>
