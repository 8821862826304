<template>
<div class="p-2">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
                <th class="shrink">Lot Number</th>
                <th>Description</th>
                <th class="shrink">Com Bidder</th>
                <th class="shrink">Amount</th>
                <th class="shrink">Reserve</th>
                <th class="shrink">Hammer</th>
                <th class="shrink">Bidder</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="lot in lots" :key="lot.id">
                <td class="shrink">{{lot.lot_no}}</td>
                <td>{{lot.description}}</td>
                <td class="shrink"></td>
                <td class="shrink"></td>
                <td class="shrink">{{lot.reserve}}</td>
                <td class="shrink">{{lot.hammer}}</td>
                <td class="shrink">{{lot.bidder_id}}</td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<style scoped>
table th, table td{
    border:1px solid #000 !important;
}
</style>

<script>
export default {
    name: 'PrintAuction',
    props:{
        auction_id: String
    },
    data(){
        return {
            lots: []
        }
    },
    mounted(){
        this.getLots();
    },
    methods:{
        getLots(){
            this.axios.get(this.apiUrl(`lots/auction/${this.auction_id}`), this.tokenHeader).then(response=>{
                this.lots = response.data;
            })
        }
    }
}
</script>
