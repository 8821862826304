import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});
const store = createStore({
    state () {
        return {
            jwt: null,
            user: null
        }
    },
    mutations: {
        token(state,jwt){
            state.jwt = jwt
        },
        user(state,user){
            state.user = user
        }
    },
    getters: {
        token: state => state.jwt,
        user: state => state.user
    },
    plugins: [vuexLocal.plugin] 
})

export default store;
